<template>
  <div class="share d-flex align-items-center">
    <h5>Share</h5>
    <button class="share-button">
        <ShareNetwork
            network="twitter"
            :url="'https://psikologi-online-staging.sclstudio.id/#/free-test/' + result.slug"
            :title="'Hasil tes MBTI ku adalah ' + result.name + '. Selengkapnya ada disini!'"
            hashtags="Phylia">
            <span><img src="@/assets/icons/icon-twitter.svg" alt="" class="icon icon-twitter"></span>
        </ShareNetwork>
    </button>
    <button class="share-button">
        <ShareNetwork
            network="whatsapp"
            :url="'https://psikologi-online-staging.sclstudio.id/#/free-test/' + result.slug"
            :title="'Hasil tes MBTI ku adalah ' + result.name + '. Selengkapnya ada disini!'"
            hashtags="Phylia">
            <span><img src="@/assets/icons/icon-whatsapp.svg" alt="" class="icon icon-whatsapp"></span>
        </ShareNetwork>
    </button>
    <button class="share-button">
        <ShareNetwork
            network="facebook"
            :url="'https://psikologi-online-staging.sclstudio.id/#/free-test/' + result.slug"
            :quote="'Hasil tes MBTI ku adalah ' + result.name + '. Selengkapnya ada disini!'"
            hashtags="Phylia">
            <span><img src="@/assets/icons/icon-facebook.svg" alt="" class="icon icon-facebook"></span>
        </ShareNetwork>
    </button>
    <!-- <a href="#">
      <img src="@/assets/icons/icon-instagram.svg" alt="" class="icon icon-instagram">
    </a> -->
        
  </div>
</template>

<script>
export default {
  name: 'ShareButton',
  props: {
    result: Object
  }
}
</script>

<style scoped>

.share-button {
  padding: 0;
  border: none;
  background-color: transparent;
}

</style>